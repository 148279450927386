import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Link } from 'react-router-dom';
import { ButtonLink } from '@naf/button-link';
import styled from 'styled-components';
import { MembershipBenefitReference } from '../../../../../types/membershipBenefitReferenceType';
import BenefitsList from '../../benefits-list/BenefitsList';

interface Props {
  title?: string;
  benefits?: MembershipBenefitReference[];
  total?: number;
}
export const MembershipBenefitsBlock = ({ title, benefits, total }: Props) =>
  (benefits && (
    <div>
      <HeadingWrapper>
        <Text variant={TextVariant.Header2}>{title || 'Medlemsfordeler'}</Text>
        <ButtonLink as={Link} to="/medlemskap/medlemsfordeler" text={`Se alle (${total?.toFixed(0)})`} />
      </HeadingWrapper>
      <BenefitsList benefitItems={benefits} />
    </div>
  )) ||
  null;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
